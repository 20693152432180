<template>
  <v-container>
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="12"
        md="6"
      >
        <h1>Video</h1>
        <v-form lazy-validation>
          <v-text-field
            v-model="video.titulo"
            label="Título"
            name="nome"
            type="text"
            :rules="rules"
          />

          <v-text-field
            v-model="video.codigoExterno"
            label="Código do Video"
            name="codigo"
            type="text"
            :rules="rules"
          />

          <v-text-field
            v-model="video.thumb"
            label="URL da thumb"
            name="thumb"
            type="text"
          />

          <v-text-field
            v-model="video.apostila"
            label="URL da apostila"
            name="apostila"
            type="text"
          />

          <v-textarea
            v-model="video.paths"
            label="Paths"
          />

          <v-autocomplete
            v-model="video.categoriaId"
            label="Categoria"
            :items="categorias"
            item-text="nome"
            item-value="categoriaId"
            :rules="rules"
          />

          <tiptap-vuetify
            v-model="video.descricao"
            :extensions="extensions"
          />

          <v-combobox
            v-model="video.chips"
            :items="tags"
            chips
            clearable
            label="Tags"
            multiple
          >
            <template v-slot:selection="{ attrs, item, select, selected }">
              <v-chip
                v-bind="attrs"
                :input-value="selected"
                close
                @click="select"
                @click:close="remove(item)"
              >
                <strong>{{ item }}</strong>&nbsp;
              </v-chip>
            </template>
          </v-combobox>

          <v-btn
            color="primary"
            @click="submit"
          >
            Salvar
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify'

  export default {
    components: { TiptapVuetify },
    data: () => ({
      categorias: [],
      tags: [],
      video: {},
      rules: [
        v => !!v || 'Esse campo é obrigatório',
      ],
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [Heading, {
          options: {
            levels: [1, 2, 3],
          },
        }],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak,
      ],
    }),
    mounted () {
      this.$http.get('/ead/categorias')
        .then(resp => {
          this.categorias = resp.data.rows
        })
      if (this.$route.params.id) {
        this.load()
      }
    },
    methods: {
      load () {
        this.$http.get(`/ead/videos/${this.$route.params.id}`)
          .then(resp => {
            this.video = resp.data
            if (this.video.tags) {
              this.video.chips = this.video.tags
            }
          })
          .catch(() => {
            this.$toast.error('Falha ao realizar operação')
          })
      },
      submit () {
        if (this.video.chips) {
          this.video.tags = this.video.chips
        }
        if (this.$route.params.id) {
          this.put()
        } else {
          this.post()
        }
      },
      post () {
        this.$http.post('/ead/videos/', this.video)
          .then(resp => {
            this.$toast.success('Operação realizada com sucesso')
            this.$router.push('/pages/ead/videos')
          })
          .catch(() => {
            this.$toast.error('Falha ao realizar operação')
          })
      },
      put () {
        this.$http.put('/ead/videos/', this.video)
          .then(resp => {
            this.$toast.success('Operação realizada com sucesso')
            this.$router.push('/pages/ead/videos')
          })
          .catch(() => {
            this.$toast.error('Falha ao realizar operação')
          })
      },
      remove (item) {
        this.video.chips.splice(this.video.chips.indexOf(item), 1)
        this.video.chips = [...this.video.chips]
      },
    },
  }
</script>

<style>
#RTE__footer__actions {
  display: none;
}
</style>
